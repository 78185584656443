<template>
    <div id="sign-mifiel-contracts">
        <vx-card v-if="isSigning" title="¡Firma tus contratos!">
            <div id="here-to-sign"></div>
        </vx-card>
        <div v-else-if="isSuccess" class="vx-row no-gutter items-center justify-center">
            <div class="vx-col sm:w-5/6 md:w-2/3 lg:w-1/2 xl:w-2/5 xxl:w-1/3">
                <vx-card class="vx-row no-gutter items-center justify-center p-6" >
                    <h5 class="mb-2">Ya has firmado el contrato. Una vez que todas las partes hayan firmado el contrato, podrás consultarlo en tu portal iniciando sesión.  Si tienes alguna duda puedes contarnos a través del Chat. Gracias por confiar en RedGirasol.
                    </h5>
                    <vs-button @click.stop="backToLogin" class="mt-2">Iniciar sesión</vs-button>
                </vx-card>
            </div>
        </div>
        <div v-else-if="isError" class="vx-row no-gutter items-center justify-center">
            <div class="vx-col sm:w-5/6 md:w-2/3 lg:w-1/2 xl:w-2/5 xxl:w-1/3">
                <vx-card class="vx-row no-gutter items-center justify-center p-6" >
                    <h5 class="mb-2">
                        Hemos tenido un problema con la firma de este documento, para continuar con tu proceso puedes contactarnos a través del Chat.
                    </h5>
                    <vs-button @click.stop="backToLogin" class="mt-2">Iniciar sesión</vs-button>
                </vx-card>
            </div>
        </div>
    </div>
</template>

<script>
import alerts from "@mixins/alerts.js"
export default {
    name: "SignMiFielContractsLanding",
    mixins: [alerts],
    data() {
        return {
            ticket: null,
            reqId: null,
            isSuccess: false,
            isError: false,
            isSigning: true
        }
    },
    mounted() {
        this.getTicket();
    },
    methods: {
        getTicket() {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            this.ticket = urlParams.get('ticket');
            this.reqId = urlParams.get('reqId');
            this.setUpWidget(this.ticket);
        },
        setUpWidget(ticket) {
            window.mifiel.widget({
                widgetId: ticket,
                appendTo: 'here-to-sign',
                successBtnText: 'Proceed to next step',
                onSuccess: {
                    // here the code you want to execute after the signer successfully sign and click the button could be an url  'mifiel.com' or a function() {}
                    callToAction: function () {
                        this.isSigning = false;
                        this.isSuccess = true;
                        this.saveSuccessNotif(null, "Se ha firmado el contrato de manera correcta, recibirás más información sobre tu proceso a través de un ejecutivo de cuenta de RedGirasol. Puedes cerrar esta página.")
                    },
                },
                onError: {
                    // here the code you want to execute after an error occurs, could be an url  'mifiel.com' or a function() {}
                    callToAction: function () {
                        this.isSigning = false;
                        this.isError = true;
                        this.missingFieldsNotif(null, "Hemos tenido problemas para continuar con tu proceso de firma, por favor ponte en contacto con nuestro equipo de soporte a través del chat.")
                    },
                },
            });

        }
    }
}
</script>

<style scoped>

</style>